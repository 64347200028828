import style from "../styles/components/LiveFeed.module.scss";
import { useLiveFeed } from "@hooks/useSocket";
import HiddenNameIcon from "@assets/icons/general/Hidden";
import Image from "next/image";
import RewardIcons from "@items/RewardIcons";
import ValueDisplay from "@items/ValueDisplay";
import Link from "next/link";
import useLanguage from "@hooks/useLanguage";
import { classnames } from "@lib/tools/helpers";
import { GhostSlot } from "./GhostSlots";
import useWallet from "@hooks/useWallet";
import { cn } from "@lib";

function HeadingIcon() {
  return (
    <div className={style["live-feed-icon"]}>
      <div className={style["circle"]} />
    </div>
  );
}

const Loading = () =>
  Array.from({ length: 12 }, (_, i) => <GhostSlot key={i} />);

function Result({
  id,
  user = {},
  payout = 0,
  animate = false,
  icon,
  game,
  walletSetting,
  currency,
  currencyPayout,
}) {
  const { username, rank } = user;
  const L = useLanguage("common");
  const amount = walletSetting === currency ? currencyPayout : payout;

  return (
    <Link
      {...classnames(style, "result", animate && "animate")}
      shallow
      href={{
        query: {
          modal: "bet",
          tab: "result",
          betId: id,
        },
      }}
    >
      <div className={style["game-image"]}>
        {icon ? (
          <Image
            src={icon}
            alt="game"
            width={90}
            height={120}
            sizes="100px"
            unoptimized
          />
        ) : (
          <div className={style["placeholder"]}>
            <div className={style["text"]}>{game.name}</div>
          </div>
        )}
      </div>
      <div className={style["game-result"]}>
        <div className={style["player"]}>
          {rank?.name && (
            <div className={style["badge"]}>
              <RewardIcons code={rank?.name?.toUpperCase()} size={"large"} />
            </div>
          )}
          <div
            className={cn(
              "text-xs text-input-label-color-light truncate max-w-[90px] flex items-center gap-1",
              !username && "text-font-color-two"
            )}
          >
            {username || L("hidden")} {!username && <HiddenNameIcon />}
          </div>
        </div>
        <div className={style["payout"]}>
          <ValueDisplay
            raw={walletSetting === currency}
            amount={amount}
            size="small"
          />
        </div>
      </div>
    </Link>
  );
}

const handleMerge = ({ data }) => {
  return +data?.multiplier > 1;
};

export default function LiveFeed({ initialData = null }) {
  const { data: results } = useLiveFeed({
    initialState: initialData,
    limit: 12,
    room: "LIVE_FEED",
    handleMerge,
  });

  const L = useLanguage("ResultFeed");
  const { walletSetting } = useWallet();

  if (results?.length === 0) return null;

  return (
    <div className={style["container"]}>
      <div className={style["heading"]}>
        <HeadingIcon />
        <div className={style["title"]}>{L("live_feed")}</div>
      </div>
      <div className={style["results"]}>
        {results === null && <Loading />}
        {results?.map((result, i) => (
          <Result
            key={result.id}
            id={result.id}
            user={result.user}
            payout={result.payout}
            game={result.game}
            walletSetting={walletSetting}
            currency={result.currency}
            currencyAmount={result.currencyAmount}
            currencyPayout={result?.currencyPayout}
            multiplier={result.multiplier}
            icon={
              result.game?.customBanner ||
              result.game?.custom_banner ||
              result.game?.icon
            }
            animate={i === 0}
          />
        ))}
      </div>
    </div>
  );
}
